import { type IAddress, type ICard, type ICity, type IOrder, type IState } from './../model'
import { type IUser } from '../model'

import parser from 'ua-parser-js'
import { type AmwalReduxProps } from '../redux/connector'
import statesDict from '../json/states-dict.json'
import citiesDict from '../json/city-dict.json'
const agent = parser(window.navigator.userAgent)
export const isIOS = agent.os.name === 'iOS'
export const isMac = agent.os.name === 'Mac OS'
export const isWindows = agent.os.name === 'Windows'
export const isChrome = agent.browser.name === 'Chrome' && !isIOS
export const isSafari = agent.browser.name === 'Safari'
export const isAndroid = agent.os.name === 'Android'
export const isMobile = isIOS || isAndroid
const isChromeIOS = agent.browser.name === 'Chrome' && isIOS
// const isWebviewIOS = agent.browser.name === 'WebKit' && isIOS
const isNonSafariIOS = agent.browser.name !== 'Mobile Safari' && isIOS
const isWebviewAndroid = agent.browser.name?.includes('WebView') && isAndroid
export const isUnknownBrowser = !agent.browser.name
export const isJohrhApp = window.navigator.userAgent.includes('Johrh')
export const isSplendApp = window.navigator.userAgent.includes('splendapp')
export const isInstagram = agent.browser.name === 'Instagram'
export const enableBiometrics = !isUnknownBrowser && !isWebviewAndroid /* && !isWebviewIOS */ && !isChromeIOS && !isInstagram && !isSplendApp
export const enableBrowserAutofill = enableBiometrics && !isNonSafariIOS
export const enableExplainer = false //! savedPhoneNumber && enableBiometrics;

export const technologyName = isIOS
  ? 'Face ID'
  : isMac
    ? 'Touch ID'
    : isWindows
      ? 'Windows Hello'
      : 'fingerprint'

declare global {
  interface Window {
    ApplePaySession?: {
      canMakePayments: () => boolean
    }
  }
}

export const isSinglePageCheckout = (props: AmwalReduxProps): boolean => {
  const filteredAddresses = props.currentUser?.addresses?.filter(address =>
    (!props.config.allowedAddressCountries || props.config.allowedAddressCountries?.includes(address.country)) &&
    (!props.config.allowedAddressStates || isAllowedState(props, address)) &&
    (!props.config.allowedAddressCities || isAllowedCity(props, address))
  )
  return process.env.REACT_APP_USE_SERVER_TO_SERVER === 'true' &&
    process.env.REACT_APP_USE_SINGLE_PAGE_CHECKOUT === 'true' &&
    !!props.currentUser?.cards?.length &&
    (!props.config.addressRequired || !!filteredAddresses?.length)
}

export const windowSupportsApplePay = (): boolean => {
  try {
    return window.ApplePaySession?.canMakePayments() ?? false
  } catch {
    return false
  }
}

export const enableApplePayiniframe = false // windowSupportsApplePay()

export const showApplePayOption = 'ApplePaySession' in window &&
  process.env.REACT_APP_SHOW_APPLE_PAY === 'true' &&
  (enableApplePayiniframe || (!isInstagram && !isSplendApp))

export const getLastUsedCardID = (user?: IUser, order?: IOrder): string | undefined => {
  return getLastUsedCard(user, order)?.id ?? 'new'
}

export const getLastUsedCard = (user?: IUser, order?: IOrder): ICard | undefined => {
  if (!user?.cards?.length) return undefined
  const lastUsedCardId = order?.type === 'PRODUCTION' ? user.last_used_card_prod : user.last_used_card_sandbox
  return user?.cards?.find((card) => card.id === lastUsedCardId) ?? user.cards?.at(-1) ?? undefined
}

export function getBroswer (): string | undefined {
  return agent.browser.name
}

export function getOS (): string | undefined {
  return agent.os.name
}

const lookupStateNames = (address: IState): string[] => {
  if (address.country in statesDict) {
    const addressCountry = address.country as keyof typeof statesDict
    const matches = statesDict[addressCountry].filter(statesList => statesList.includes(address.state))
    if (matches.length > 0) return matches[0]
  }
  return [address.state]
}

const lookupCityNames = (address: ICity): string[] => {
  if (address.country in citiesDict) {
    const addressCountry = address.country as keyof typeof citiesDict
    const countryCitiesDict = citiesDict[addressCountry]
    const possibleAddressState = lookupStateNames(address).find(state => state in countryCitiesDict)
    if (possibleAddressState) {
      const addressState = possibleAddressState as keyof typeof countryCitiesDict
      const stateCitiesDict = countryCitiesDict[addressState]
      const matches = stateCitiesDict.filter(cityList => cityList.includes(address.city))
      if (matches.length > 0) return matches[0]
    }
  }
  return [address.city]
}

export const isAllowedState = (props: AmwalReduxProps, address: IState): boolean => {
  if (props.config.allowedAddressStates && address.country in props.config.allowedAddressStates) {
    const allowedStates = Object.values(props.config.allowedAddressStates[address.country])
    const possibleStateNames = lookupStateNames(address)
    return possibleStateNames.some(state => allowedStates.includes(state))
  } else {
    return true
  }
}

export const isAllowedCity = (props: AmwalReduxProps, address: ICity): boolean => {
  if (props.config.allowedAddressCities && address.country in props.config.allowedAddressCities) {
    const allowedCitiesByState = props.config.allowedAddressCities[address.country]
    const stateCode = getStateCode(props, address)
    if (stateCode in allowedCitiesByState) {
      const allowedCities = allowedCitiesByState[stateCode]
      const possibleCityNames = lookupCityNames(address)
      return possibleCityNames.some(city => allowedCities.includes(city))
    }
  }
  return true
}

export const getStateCode = (props: AmwalReduxProps, address: IState): string => {
  if (props.config.allowedAddressStates && address.country in props.config.allowedAddressStates) {
    const statesDict = props.config.allowedAddressStates[address.country]
    const possibleStateNames = lookupStateNames(address)
    return Object.keys(statesDict).find(a => possibleStateNames.includes(statesDict[a])) ?? ''
  }
  return ''
}

export const getAllowedState = (props: AmwalReduxProps, address: IState): string => {
  if (props.config.allowedAddressStates && address.country in props.config.allowedAddressStates) {
    const statesDict = props.config.allowedAddressStates[address.country]
    const possibleStateNames = lookupStateNames(address)
    return Object.values(statesDict).find(state => possibleStateNames.includes(state)) ?? ''
  }
  return address.state
}

export const getAllowedCity = (props: AmwalReduxProps, address: ICity): string => {
  if (props.config.allowedAddressCities && address.country in props.config.allowedAddressCities) {
    const allowedCitiesByState = props.config.allowedAddressCities[address.country]
    const stateCode = getStateCode(props, address)
    if (stateCode in allowedCitiesByState) {
      const allowedCities = Object.values(allowedCitiesByState[stateCode])
      const possibleCityNames = lookupCityNames(address)
      return allowedCities.find((city: string) => possibleCityNames.includes(city)) ?? ''
    }
  }
  return address.city
}

export const getNaNStateCode = (props: AmwalReduxProps, address: IState): string | undefined => {
  if (props.config.allowedAddressStates && address.country in props.config.allowedAddressStates) {
    const statesDict = props.config.allowedAddressStates[address.country]
    if (Array.isArray(statesDict)) return undefined
    const possibleStateNames = lookupStateNames(address)
    return Object.keys(statesDict).find(a => possibleStateNames.includes(statesDict[a]))
  }
}

export const getPossibleAddresses = (props: AmwalReduxProps): IAddress[] => {
  return props.currentUser?.addresses?.filter(address =>
    (!props.config.allowedAddressCountries || props.config.allowedAddressCountries?.includes(address.country)) &&
    (!props.config.allowedAddressStates || isAllowedState(props, address)) &&
    (!props.config.allowedAddressCities || isAllowedCity(props, address))
  ) ?? []
}

export const showBankInstallment = (props: AmwalReduxProps): boolean => {
  return !!(props.config.enableBankInstallments && props.order && props.order?.total_amount >= 1)
}

export const isBillingAddressRequired = (props: AmwalReduxProps): boolean => {
  return (props.order?.billing_address_required ?? true)
}
